import React from "react";
import "./services.css";
import ProgressiveImage from "../../components/ProgressiveImage";
import serviceBannerImg from "../../assets/images/735.jpg";
import Banner from "../../components/Banner";
import exportIcon from "../../assets/images/icons/Group47.png";
import miningIcon from "../../assets/images/icons/Group53.png";
import investmentsIcon from "../../assets/images/icons/Group49.png";
import riceImg from "../../assets/images/13600.jpg";
import maizeImg from "../../assets/images/pexels-pixabay-60507.jpg";
import wheatImg from "../../assets/images/wheat.jpg";
import cookingOilImg from "../../assets/images/pexels-maxavans-5056853.jpg";
import goldLeftImg from "../../assets/images/goldleft.jpg";
import goldRightImg from "../../assets/images/goldright.jpg";
import diamondLeftImg from "../../assets/images/diamondleft.jpg";
import diamondRightImg from "../../assets/images/diamondright.jpg";
import diamond from "../../assets/images/28856.jpg";
import gold from "../../assets/images/pexels-pixabay-47047.jpg";
import invest1 from "../../assets/images/invest1.jpg";
import invest2 from "../../assets/images/invest2.jpg";
import invest3 from "../../assets/images/invest3.jpg";
import invest4 from "../../assets/images/invest4.jpg";
import invest5 from "../../assets/images/invest5.jpg";
import invest6 from "../../assets/images/invest6.jpg";
import ScrollToTop from "../../components/ScrollToTop";

const Services = () => {
  return (
    <>
      <Banner title={"our services"} bannerImage={serviceBannerImg} />
      <section className="exportAndImportGroup commonSection">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="iconHolder">
                <ProgressiveImage
                  lowQualitySrc={exportIcon.lowQualityImage}
                  highQualitySrc={exportIcon}
                  alt={"Exports & Imports"}
                  className={"icon"}
                />
              </div>
              <div className="txtHeader">
                <h2>exports & imports</h2>
              </div>
              <div className="txt">
                <p>
                  We facilitate seamless cross-border trade, connecting markets
                  with a diverse range of high-quality commodities. Our
                  extensive network ensures timely and efficient transactions,
                  making us a trusted partner in global trade.
                </p>
              </div>
              <div className="sub">
                <div className="subHeader">
                  <h3>export and import services</h3>
                </div>
                <span>Types of Goods Exported/Imported</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="commodities">
              <div className="header subHeader">
                <h3>general commodities</h3>
              </div>
              <div className="commodityCards">
                <div className="commodityCard">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={riceImg.lowQualityImage}
                      highQualitySrc={riceImg}
                      alt={"rice"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>rice</h3>
                    </div>
                    <div className="txt">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Dolore saepe praesentium ducimus accusantium quia
                        consequuntur enim minus animi a amet!
                      </p>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnPrimary">get a quote</button>
                  </div>
                </div>

                <div className="commodityCard">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={maizeImg.lowQualityImage}
                      highQualitySrc={maizeImg}
                      alt={"maize"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>maize</h3>
                    </div>
                    <div className="txt">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Dolore saepe praesentium ducimus accusantium quia
                        consequuntur enim minus animi a amet!
                      </p>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnPrimary">get a quote</button>
                  </div>
                </div>

                <div className="commodityCard">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={wheatImg.lowQualityImage}
                      highQualitySrc={wheatImg}
                      alt={"wheat"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>wheat</h3>
                    </div>
                    <div className="txt">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Dolore saepe praesentium ducimus accusantium quia
                        consequuntur enim minus animi a amet!
                      </p>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnPrimary">get a quote</button>
                  </div>
                </div>

                <div className="commodityCard">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={cookingOilImg.lowQualityImage}
                      highQualitySrc={cookingOilImg}
                      alt={"cooking oil"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>cooking oil</h3>
                    </div>
                    <div className="txt">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Dolore saepe praesentium ducimus accusantium quia
                        consequuntur enim minus animi a amet!
                      </p>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnPrimary">get a quote</button>
                  </div>
                </div>
              </div>
              <div className="mineralCommodity">
                <div className="header subHeader">
                  <h3>minerals</h3>
                </div>
                <div className="hor">
                  <div className="ver">
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={gold.lowQualityImage}
                        highQualitySrc={gold}
                        alt={"cooking oil"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="txtContent">
                      <div className="txtHeader subHeader">
                        <h3>gold</h3>
                      </div>
                      <div className="txt">
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Dolore saepe praesentium ducimus accusantium
                          quia consequuntur enim minus animi a amet! Lorem ipsum
                          dolor sit amet consectetur adipisicing elit.
                          Voluptatibus praesentium vel distinctio, voluptatem ab
                          officia minus odit maxime ratione mollitia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ver">
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={diamond.lowQualityImage}
                        highQualitySrc={diamond}
                        alt={"cooking oil"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="txtContent">
                      <div className="txtHeader subHeader">
                        <h3>diamond</h3>
                      </div>
                      <div className="txt">
                        <p>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Dolore saepe praesentium ducimus accusantium
                          quia consequuntur enim minus animi a amet! Lorem
                          ipsum, dolor sit amet consectetur adipisicing elit.
                          Ducimus error ratione eveniet perspiciatis. Iste iusto
                          perspiciatis dolorum saepe molestias incidunt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="miningCommodity commonSection">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="iconHolder">
                <ProgressiveImage
                  lowQualitySrc={miningIcon.lowQualityImage}
                  highQualitySrc={miningIcon}
                  alt={"Mining"}
                  className={"icon"}
                />
              </div>
              <div className="txtHeader">
                <h2>mining</h2>
              </div>
              <div className="txt">
                <p>
                  Our mining operations focus on the sustainable extraction of
                  precious minerals, including gold, bauxite, and diamonds.
                  Committed to responsible mining practices, we ensure minimal
                  environmental impact while delivering premium resources.
                </p>
              </div>
              <div className="sub">
                <div className="subHeader">
                  <h3>our mining capabilities</h3>
                </div>
                <span>Types of Minerals</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="miningCommodityGrid">
              <div className="hor">
                <div className="ver">
                  <div className="imgTile">
                    <div className="leftImg">
                      <ProgressiveImage
                        lowQualitySrc={goldLeftImg.lowQualityImage}
                        highQualitySrc={goldLeftImg}
                        alt={"Mining"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="rightImg">
                      <ProgressiveImage
                        lowQualitySrc={goldRightImg.lowQualityImage}
                        highQualitySrc={goldRightImg}
                        alt={"Mining"}
                        className={"commodityImage"}
                      />
                    </div>
                  </div>
                </div>
                <div className="ver">
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>gold</h3>
                    </div>
                    <div className="txt txtPaddingLeftRight">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Delectus praesentium dolore officia accusantium
                        fuga quo reiciendis tempore itaque corrupti
                        voluptatibus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hor mobile-column-reverse">
                <div className="ver">
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>diamond</h3>
                    </div>
                    <div className="txt txtPaddingLeftRight">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Delectus praesentium dolore officia accusantium
                        fuga quo reiciendis tempore itaque corrupti
                        voluptatibus.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ver">
                  <div className="imgTile">
                    <div className="leftImg">
                      <ProgressiveImage
                        lowQualitySrc={diamondLeftImg.lowQualityImage}
                        highQualitySrc={diamondLeftImg}
                        alt={"Mining"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="rightImg">
                      <ProgressiveImage
                        lowQualitySrc={diamondRightImg.lowQualityImage}
                        highQualitySrc={diamondRightImg}
                        alt={"Mining"}
                        className={"commodityImage"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="investmentsGroup commonSection ">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="iconHolder">
                <ProgressiveImage
                  lowQualitySrc={investmentsIcon.lowQualityImage}
                  highQualitySrc={investmentsIcon}
                  alt={"Mining"}
                  className={"icon"}
                />
              </div>
              <div className="txtHeader">
                <h2>investments</h2>
              </div>
              <div className="txt">
                <p>
                  We provide attractive investment opportunities in the mining
                  sector, offering sustainable returns through ethical and
                  eco-friendly operations. Our investment solutions are designed
                  to foster growth and profitability for our stakeholders.
                </p>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="investmentCommodityGrid">
              <div className="hor">
                <div className="ver">
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>investment opportunities</h3>
                    </div>
                    <div className="txt ">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Delectus praesentium dolore officia accusantium
                        fuga quo reiciendis tempore itaque corrupti
                        voluptatibus.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ver">
                  <div className="imgGrid">
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest1.lowQualityImage}
                        highQualitySrc={invest1}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest2.lowQualityImage}
                        highQualitySrc={invest2}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hor">
                <div className="ver">
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>drito will invest in your mining company</h3>
                    </div>
                    <div className="txt ">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Delectus praesentium dolore officia accusantium
                        fuga quo reiciendis tempore itaque corrupti
                        voluptatibus.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ver">
                  <div className="imgGrid">
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest3.lowQualityImage}
                        highQualitySrc={invest3}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest4.lowQualityImage}
                        highQualitySrc={invest4}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hor">
                <div className="ver">
                  <div className="txtContent">
                    <div className="txtHeader subHeader">
                      <h3>drito is open to investors</h3>
                    </div>
                    <div className="txt ">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Delectus praesentium dolore officia accusantium
                        fuga quo reiciendis tempore itaque corrupti
                        voluptatibus.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ver">
                  <div className="imgGrid">
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest5.lowQualityImage}
                        highQualitySrc={invest5}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                    <div className="imgWrapper">
                      <ProgressiveImage
                        lowQualitySrc={invest6.lowQualityImage}
                        highQualitySrc={invest6}
                        alt={"investments"}
                        className={"commodityImage"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="investmentOffice">
        <div className="container">
          <div className="addressWrapper">
            <div className="header subHeader">
              <h3>locate our investment office</h3>
            </div>
            <div className="top">
              <div className="hor">
                <div className="ver ">
                  <div className="subHeader">
                    <h3>USA</h3>
                  </div>
                </div>
                <div className="ver flex-2">
                  <div className="txt">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Expedita aspernatur eligendi eius quis. Facilis harum
                      neque quasi debitis repellendus similique?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="hor">
                <div className="ver">
                  <div className="subHeader">
                    <h3>UGANDA</h3>
                  </div>
                </div>
                <div className="ver flex-2">
                  <div className="txt">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Expedita aspernatur eligendi eius quis. Facilis harum
                      neque quasi debitis repellendus similique?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Services;
