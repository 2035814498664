import React from "react";
import "./home.css";
import heroVid from "../../assets/videos/ship-on-the-sea0001-0271.mp4";
import { Link } from "react-router-dom";
import ProgressiveImage from "../../components/ProgressiveImage";
import roots from "../../assets/images/roots.png";
import posterImage from "../../assets/images/ship-on-sea.png";
import sustainabilityImg from "../../assets/images/sustainability.png";
import ServiceCards from "../../components/ServiceCards";
import Testimonials from "../../components/Testimonials";
import ScrollToTop from "../../components/ScrollToTop";
import ProgressiveVideo from "../../components/ProgressiveVideo";

const Home = () => {
  return (
    <>
      <section className="hero">
        {/* <div className="video__overlay" /> */}

        <ProgressiveVideo
          lowQualitySrc={heroVid}
          highQualitySrc={heroVid}
          posterSrc={posterImage}
          alt="cargo ship on sea"
          className="hero-bgVid"
        />
      </section>
      <section className="aboutSection">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txtHeader">
                <h2>about us</h2>
              </div>
              <div className="txt">
                <p>
                  Welcome to DRITO Global Corporation (DGC), your trusted
                  partner in the fields of exports, imports, mining, and
                  investments. With a foundation deeply rooted in Uganda, we are
                  dedicated to driving sustainable growth and fostering economic
                  development both locally and globally. Our mission is to
                  deliver unparalleled value through integrity, innovation, and
                  excellence, positioning DGC as a leader in our industry.
                </p>
              </div>
              <div className="btnWrapper">
                <Link to="/about-us" className="readMoreBtn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="aboutCardsWrapper CardsWrapper">
              <div className="aboutCard missionBkg">
                <div className="aboutCardTxtContent">
                  <div className="header">
                    <h3>
                      our <br />
                      mission
                    </h3>
                  </div>
                  <div className="txt">
                    <p>
                      To be a trusted partner in global trade and resource
                      management, delivering value through integrity,
                      innovation, and excellence.
                    </p>
                  </div>
                </div>
              </div>

              <div className="aboutCard visionBkg">
                <div className="aboutCardTxtContent">
                  <div className="header">
                    <h3>
                      our <br />
                      vision
                    </h3>
                  </div>
                  <div className="txt">
                    <p>
                      To create a sustainable future by responsibly harnessing
                      natural resources and fostering economic growth
                    </p>
                  </div>
                </div>
              </div>

              <div className="aboutCard coreValuesBkg">
                <div className="aboutCardTxtContent">
                  <div className="header">
                    <h3>
                      our core
                      <br />
                      values
                    </h3>
                  </div>
                  <div className="txt">
                    <p>
                      The foundation of our business. They guide our actions,
                      shape our culture, and reflect our commitment to
                      excellence, sustainability, and ethical practices.
                    </p>
                  </div>
                  <div className="aboutCardBtn">
                    <Link to={"/about"} className="learnMoreBtn">
                      learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourRoots">
        <div className="hor">
          <div className="ver flex-2">
            <div className="container">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2>our roots</h2>
                </div>
                <div className="txt">
                  <p className="blue boldParagraph">
                    Brief history of DGC, highlighting its Ugandan roots and
                    growth into a global player.
                  </p>
                  <p>
                    Founded in 2005, DRITO Global Corporation (DGC) began as a
                    small export-import business in the bustling trade hubs of
                    Kampala, Uganda. Driven by a vision to connect Uganda's rich
                    natural resources with global markets, our founders, a team
                    of passionate entrepreneurs, laid the groundwork for what
                    would become a pioneering force in the industry.
                  </p>
                  <p>
                    In the early years, DGC focused on trading agricultural
                    products and essential commodities, establishing a
                    reputation for reliability and excellence. As our network
                    expanded, so did our ambitions. Recognizing the untapped
                    potential in Uganda’s mineral-rich regions, we ventured into
                    the mining sector, commencing our first gold mining
                    operations in 2010.
                  </p>
                </div>
                <div className="btnWrapper">
                  <Link to="/about-us" className="readMoreBtn">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="ver ">
            <div className="backdrop">
              <ProgressiveImage
                lowQualitySrc={roots.lowQualityImage}
                highQualitySrc={roots}
                alt={"our roots"}
                className={"rootsImage"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="ourServices">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txtHeader">
                <h2>our services</h2>
              </div>
              <div className="txt">
                <p>
                  At DRITO Global Corporation (DGC), we deliver exceptional
                  value and reliable solutions in exports, imports, mining, and
                  investments through our expertise and global reach.
                </p>
              </div>
            </div>
          </div>
          <div className="bottom">
            <ServiceCards />
          </div>
        </div>
      </section>
      <section className="commitment">
        <div className="container">
          <div className="top">
            <div className="imageWrapper">
              <ProgressiveImage
                lowQualitySrc={sustainabilityImg.lowQualityImage}
                highQualitySrc={sustainabilityImg}
                alt={"our commitment to sustainability"}
                className={"commitmentImage"}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="txtContent">
              <div className="txtHeader">
                <h2>Our Commitment to Sustainability</h2>
              </div>
              <div className="txt">
                <p>
                  We at Drito are committed to encouraging environmentally
                  friendly methods in all facets of our mining, importing, and
                  exporting business. Our goal is to ensure a better future for
                  future generations by striking a balance between social
                  responsibility, environmental conservation, and economic
                  prosperity.
                </p>
              </div>
              <div className="btnWrapper">
                <Link to={"/sustainability"} className="btnPrimary">
                  learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <ScrollToTop />
    </>
  );
};

export default Home;
