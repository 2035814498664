import React from "react";
import "./banner.css";
import ProgressiveImage from "../ProgressiveImage";

const Banner = ({ title, bannerImage }) => {
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="bannerContent">
            <div className="bannerTxtContent">
              <div className="txtHeader">
                <h2>{title}</h2>
              </div>
            </div>
            <div className="bannerImageWrapper">
              <ProgressiveImage
                lowQualitySrc={bannerImage.lowQualityImage}
                highQualitySrc={bannerImage}
                alt={"banner"}
                className={"bannerImage"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
