import { createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import Home from "../pages/home";
import AboutUs from "../pages/about-us";
import ContactUs from "../pages/contact-us";
import NewsAndUpdates from "../pages/news-and-updates";
import Services from "../pages/services";
import Sustainability from "../pages/sustainability";
import NotFound from "../pages/not-found";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "sustainability", element: <Sustainability /> },
      { path: "news-and-updates", element: <NewsAndUpdates /> },
      { path: "services", element: <Services /> },

      // { path: 'events', element: <Events /> },
      // {
      //     path: 'events',
      //     element: <EventDetailLayout />,
      //     children: [
      //         { path: 'past-events/:eventId', element: <EventDetail /> },
      //         { path: 'upcoming-events/:upcomingEventId', element: <ComingEvents /> }
      //     ]
      // },

      { path: "*", element: <NotFound /> },
      // { path: 'privacy-policy', element: <PrivacyPolicy /> },
      // { path: 'cookie-policy', element: <CookiePolicy /> },
      // { path: 'terms-and-conditions', element: <TermsAndConditions /> },
    ],
  },
]);

export default router;
