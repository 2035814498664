import React from "react";
import "./contact.css";
import { FaPhone } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import cargoImg from "../../assets/images/business-logistics-concept-container-cargo.jpg";
import ProgressiveImage from "../../components/ProgressiveImage";
import ContactForm from "../../components/forms/contactForm";
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";

const ContactUs = () => {
  return (
    <>
      <section className="contactHeader">
        <div className="container">
          <div className="txtContent">
            <div className="txtHeader">
              <h1>contact us</h1>
            </div>
            <div className="txt">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio
                repellendus veritatis dicta mollitia dolore excepturi? At unde
                reiciendis optio a!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contactInfoWrapper">
        <div className="container">
          <div className="hor addressInfo">
            <div className="ver">
              <h4>Contact Information</h4>
              <div className="hor">
                <div className="ver">
                  <div className="c-infoElement">
                    <div className="iconContainer">
                      <FaPhone className="info-icon" />
                    </div>
                    <div className="c-details">
                      <div className="top">(+ 44 887 7776 80)</div>
                      <div className="bottom">Lorem, ipsum dolor.</div>
                    </div>
                  </div>
                </div>

                <div className="ver">
                  <div className="c-infoElement">
                    <div className="iconContainer">
                      <FaEnvelope className="info-icon" />
                    </div>
                    <div className="c-details">
                      <div className="top">contact@dritoglobalcorp.com</div>
                      <div className="bottom">Lorem, ipsum dolor.</div>
                    </div>
                  </div>
                </div>

                <div className="ver">
                  <div className="c-infoElement">
                    <div className="iconContainer">
                      <FaLocationDot className="info-icon" />
                    </div>
                    <div className="c-details">
                      <div className="top">Uganda/USA</div>
                      <div className="bottom">Lorem, ipsum dolor.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <ProgressiveImage
                  lowQualitySrc={cargoImg.lowQualityImage}
                  highQualitySrc={cargoImg}
                  alt={"cargo trucks"}
                  className={"commodityImage"}
                />
              </div>
            </div>
          </div>
          <div className="hor formAndAddress">
            <div className="ver">
              <div className="contactForm">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h2>get in touch</h2>
                  </div>
                  <div className="txt">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Rerum accusamus quaerat temporibus possimus magni
                      molestiae alias corporis tempore ratione iure.
                    </p>
                  </div>
                </div>
                <div className="formWrapper">
                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="ver location">
              <div className="address">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h5>our location</h5>
                  </div>
                  <div className="txt">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Rerum accusamus quaerat temporibus possimus magni
                      molestiae alias corporis tempore ratione iure.
                    </p>
                  </div>
                </div>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4084402.321492104!2d29.660454625591825!3d1.372202781895549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1771a69f6499f945%3A0x874155ce43014549!2sUganda!5e0!3m2!1sen!2sgh!4v1725250704504!5m2!1sen!2sgh"
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="socialMedia">
                  <div className="header">
                    <h5>social media</h5>{" "}
                  </div>
                  <div className="socialMediaIcons">
                    <Link
                      className="icons"
                      target="_blank"
                      to={"https://www.facebook.com"}
                    >
                      <FaFacebookF className="icon" />
                    </Link>

                    <Link
                      className="icons"
                      target="_blank"
                      to={"https://www.twitter.com"}
                    >
                      <FaXTwitter className="icon" />
                    </Link>

                    <Link
                      className="icons"
                      target="_blank"
                      to={"https://www.tiktok.com"}
                    >
                      <FaTiktok className="icon" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default ContactUs;
