import React from "react";
import "./news-and-updates.css";

const NewsAndUpdates = () => {
  return (
    <>
      <div>NewsAndUpdates</div>
    </>
  );
};

export default NewsAndUpdates;
