import React from "react";
import "./about.css";
import Testimonials from "../../components/Testimonials";
import ProgressiveImage from "../../components/ProgressiveImage";
import avatar from "../../assets/images/2148931095.jpg";
import trustIcon from "../../assets/images/icons/trust.png";
import earthIcon from "../../assets/images/icons/earth.png";
import excellentIcon from "../../assets/images/icons/excellent.png";
import { Link } from "react-router-dom";
import ServiceCards from "../../components/ServiceCards";
import missionImg from "../../assets/images/16430.jpg";
import visionImg from "../../assets/images/nathan-dumlao-VJHb4QPBgV4-unsplash.jpg";
import ScrollToTop from "../../components/ScrollToTop";

const AboutUs = () => {
  return (
    <>
      <section className="aboutSummary">
        <div className="container">
          <div className="txtContent">
            <div className="txtHeader">
              <h2>about us</h2>
            </div>
            <div className="txt">
              <p>
                Welcome to DRITO Global Corporation (DGC), your trusted partner
                in the fields of exports, imports, mining, and investments. With
                a foundation deeply rooted in Uganda, we are dedicated to
                driving sustainable growth and fostering economic development
                both locally and globally. Our mission is to deliver
                unparalleled value through integrity, innovation, and
                excellence, positioning DGC as a leader in our industry.
              </p>
            </div>
          </div>
        </div>
        <div className="missionWrapper">
          <div className="container">
            <div className="hor">
              <div className="ver">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h2>our mission</h2>
                  </div>
                  <div className="txt">
                    <p>
                      To be a trusted partner in global trade and resource
                      management, delivering value through integrity,
                      innovation, and excellence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ver stripImageContainer">
                <div className="imageWrapper stripImageWrapper right">
                  <ProgressiveImage
                    lowQualitySrc={missionImg.lowQualityImage}
                    highQualitySrc={missionImg}
                    alt={"mission"}
                    className={"stripImage"}
                  />
                </div>
                <div className="strip right"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="visionWrapper ">
          <div className="container">
            <div className="hor">
              <div className="ver stripImageContainer">
                <div className="imageWrapper stripImageWrapper left">
                  <ProgressiveImage
                    lowQualitySrc={visionImg.lowQualityImage}
                    highQualitySrc={visionImg}
                    alt={"vision"}
                    className={"stripImage"}
                  />
                </div>
                <div className="strip left"></div>
              </div>
              <div className="ver">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h2>our vision</h2>
                  </div>
                  <div className="txt">
                    <p>
                      To create a sustainable future by responsibly harnessing
                      natural resources and fostering economic growth
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutGroup">
        <div className="container">
          <div className="coreValues">
            <div className="left">
              <div className="valueBars">
                <div className="valueBar">
                  <div className="iconHolder">
                    <ProgressiveImage
                      lowQualitySrc={trustIcon.lowQualityImage}
                      highQualitySrc={trustIcon}
                      alt={"trust"}
                      className={"icon"}
                    />
                  </div>
                  <div className="value">
                    <h3>Integrity</h3>
                  </div>
                </div>

                <div className="valueBar">
                  <div className="iconHolder">
                    <ProgressiveImage
                      lowQualitySrc={excellentIcon.lowQualityImage}
                      highQualitySrc={excellentIcon}
                      alt={"excellence"}
                      className={"icon"}
                    />
                  </div>
                  <div className="value">
                    <h3>Excellence</h3>
                  </div>
                </div>

                <div className="valueBar">
                  <div className="iconHolder">
                    <ProgressiveImage
                      lowQualitySrc={earthIcon.lowQualityImage}
                      highQualitySrc={earthIcon}
                      alt={"earth"}
                      className={"icon"}
                    />
                  </div>
                  <div className="value">
                    <h3>Sustainability</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2>our core values</h2>
                </div>
                <div className="txt">
                  <p>
                    The foundation of our business. They guide our actions,
                    shape our culture, and reflect our commitment to excellence,
                    sustainability, and ethical practices. These principles
                    drive us to
                  </p>
                </div>
                <div className="btnWrapper">
                  <Link to="/about-us" className="readMoreBtn">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="history">
            <div className="txtContent">
              <div className="header">
                <h2>Our history</h2>
              </div>
              <div className="txt">
                <p>
                  Founded in 2005, DRITO Global Corporation (DGC) began as a
                  small export-import business in the bustling trade hubs of
                  Kampala, Uganda. Driven by a vision to connect Uganda's rich
                  natural resources with global markets, our founders, a team of
                  passionate entrepreneurs, laid the groundwork for what would
                  become a pioneering force in the industry.
                </p>
                <p>
                  In the early years, DGC focused on trading agricultural
                  products and essential commodities, establishing a reputation
                  for reliability and excellence. As our network expanded, so
                  did our ambitions. Recognizing the untapped potential in
                  Uganda’s mineral-rich regions, we ventured into the mining
                  sector, commencing our first gold mining operations in 2010.
                </p>
              </div>
              <div className="btnWrapper">
                <Link to="/about-us" className="readMoreBtn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="ourServicesWrapper">
          <div className="container">
            <div className="header">
              <h2>Our services</h2>
            </div>
          </div>
          <div className="servicesContent">
            <div className="container">
              <ServiceCards />
            </div>
          </div>
        </div>
      </section>
      <section className="ourTeam">
        <div className="container">
          <div className="header">
            <h2>our team</h2>
          </div>
          <div className="teamCards">
            <div className="teamCard smaller-card">
              <div className="avatarWrapper">
                <ProgressiveImage
                  lowQualitySrc={avatar.lowQualityImage}
                  highQualitySrc={avatar}
                  alt={"avatar"}
                  className={"avatar"}
                />
              </div>
              <div className="details">
                <div className="name">Name: John Doe</div>
                <div className="position">Position: C.E.O</div>
              </div>
            </div>

            <div className="teamCard bigger-card">
              <div className="avatarWrapper">
                <ProgressiveImage
                  lowQualitySrc={avatar.lowQualityImage}
                  highQualitySrc={avatar}
                  alt={"avatar"}
                  className={"avatar"}
                />
              </div>
              <div className="details">
                <div className="name">Name: John Doe</div>
                <div className="position">Position: C.E.O</div>
              </div>
            </div>

            <div className="teamCard smaller-card">
              <div className="avatarWrapper">
                <ProgressiveImage
                  lowQualitySrc={avatar.lowQualityImage}
                  highQualitySrc={avatar}
                  alt={"avatar"}
                  className={"avatar"}
                />
              </div>
              <div className="details">
                <div className="name">Name: John Doe</div>
                <div className="position">Position: C.E.O</div>
              </div>
            </div>
          </div>

          <div className="caseStudy">
            <div className="header">
              <h2>case study</h2>
            </div>
            <div className="casesWrapper">
              <div className="cases">
                <div className="case">
                  <article>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Voluptas, iusto quaerat illum maiores eum, quia natus
                      nobis molestiae aliquam cupiditate provident porro fuga
                      qui est, exercitationem ab totam? Praesentium,
                      perspiciatis! Temporibus voluptates pariatur
                    </p>
                  </article>
                  <div className="btnWrapper">
                    <Link to={"/about"} className="learnMoreBtn">
                      learn more
                    </Link>
                  </div>
                </div>

                <div className="case line">
                  <article>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Voluptas, iusto quaerat illum maiores eum, quia natus
                      nobis molestiae aliquam cupiditate provident porro fuga
                      qui est, exercitationem ab totam? Praesentium,
                      perspiciatis! Temporibus voluptates pariatur
                    </p>
                  </article>
                  <div className="btnWrapper">
                    <Link to={"/about"} className="learnMoreBtn">
                      learn more
                    </Link>
                  </div>
                </div>

                <div className="case">
                  <article>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Voluptas, iusto quaerat illum maiores eum, quia natus
                      nobis molestiae aliquam cupiditate provident porro fuga
                      qui est, exercitationem ab totam? Praesentium,
                      perspiciatis! Temporibus voluptates pariatur
                    </p>
                  </article>
                  <div className="btnWrapper">
                    <Link to={"/about"} className="learnMoreBtn">
                      learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <ScrollToTop />
    </>
  );
};

export default AboutUs;
