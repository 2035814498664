import React from "react";
import "./testimonials.css";
import quotationMarkImg from "../../assets/images/icons/Group55.png";
import ProgressiveImage from "../ProgressiveImage";

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="container">
        <div className="top">
          <div className="txtContent">
            <div className="txtHeader">
              <h2>Testimonials</h2>
            </div>
            <div className="txt">
              <p>
                Hear what our partners and clients have to say about their
                experiences with DRITO Global Corporation
              </p>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="testimonyWrapper">
            <div className="icon">
              <ProgressiveImage
                lowQualitySrc={quotationMarkImg.lowQualityImage}
                highQualitySrc={quotationMarkImg}
                alt={"quotation marks"}
                className={"quotationIcon"}
              />
            </div>
            <div className="testimonies">
              <div className="testimony">
                <div className="testimonyTxt">
                  <p>
                    "Working with Drito Global Corporation has been a
                    game-changer for our business. Their experise in exports and
                    imports, coupled with their unwavering commitment to
                    integrity and sustainability, has made them an invaluable
                    partner. We look forward to many more years of successful
                    collaboration."
                  </p>
                </div>
                <div className="testifier">
                  <span>John Mwangi, CEO of Global Trade Solutions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
