export const navigationLinks = [
  {
    id: "1",
    name: "home",
    url: "/",
  },
  {
    id: "2",
    name: "about us",
    url: "/about-us",
  },
  {
    id: "3",
    name: "services",
    url: "/services",
    // submenu: [
    //   {
    //     id: "4",
    //     name: "exports & imports",
    //     url: "/services/exports-and-imports",
    //   },
    //   {
    //     id: "5",
    //     name: "mining",
    //     url: "/services/mining",
    //   },
    //   {
    //     id: "6",
    //     name: "investments",
    //     url: "/services/investments",
    //   },
    // ],
  },

  {
    id: "7",
    name: "sustainability",
    url: "/sustainability",
  },
  // {
  //   id: "8",
  //   name: "news & updates",
  //   url: "/news-and-updates",
  // },
  {
    id: "9",
    name: "contact us",
    url: "/contact-us",
  },
];

export const ourServiceLinks = [
  {
    id: "1",
    name: "exports & imports",
    url: "/services/exports-and-imports",
  },
  {
    id: "2",
    name: "mining",
    url: "/services/mining",
  },
  {
    id: "3",
    name: "investments",
    url: "/services/investments",
  },
];

export const footerMenuLinks = {
  supportLinks: {
    title: "support",
    links: [
      {
        id: "1",
        name: "privacy policy",
        url: "/privacy-policy",
      },
      {
        id: "2",
        name: "cookie policy",
        url: "/cookie-policy",
      },
      {
        id: "3",
        name: "terms and conditions",
        url: "/terms-and-conditions",
      },
    ],
  },
  menuLinks: {
    title: "menu",
    links: [
      {
        id: "1",
        name: "about us",
        url: "/about-us",
      },
      {
        id: "2",
        name: "events ",
        url: "/events",
      },
      {
        id: "3",
        name: "BIPOC Form",
        url: "https://form.jotform.com/232890162361050",
      },
      {
        id: "4",
        name: "membership",
        url: "/membership",
      },
      {
        id: "5",
        name: "contact us",
        url: "/contact-us",
      },
      {
        id: "6",
        name: "how to donate",
        url: "/donate",
      },
      {
        id: "7",
        name: "Resources",
        url: "/resources",
      },
    ],
  },
};
