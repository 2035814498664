import React, { useState, useEffect, useRef } from "react";

const ProgressiveVideo = ({
  lowQualitySrc,
  highQualitySrc,
  posterSrc,
  alt,
  className,
}) => {
  const [src, setSrc] = useState(lowQualitySrc);
  const [isHighQualityLoaded, setIsHighQualityLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const highQualityVideo = document.createElement("video");
    highQualityVideo.src = highQualitySrc;
    highQualityVideo.preload = "auto";

    highQualityVideo.oncanplaythrough = () => {
      setSrc(highQualitySrc);
      setIsHighQualityLoaded(true);
    };

    return () => {
      highQualityVideo.oncanplaythrough = null;
    };
  }, [highQualitySrc]);

  const handleVideoLoad = () => {
    if (videoRef.current && isHighQualityLoaded) {
      videoRef.current.play();
    }
  };

  return (
    <video
      ref={videoRef}
      src={src}
      poster={posterSrc}
      alt={alt}
      className={`${className} ${isHighQualityLoaded ? "loaded" : "loading"}`}
      style={{
        filter: isHighQualityLoaded ? "none" : "blur(10px)",
        transition: "filter 0.3s ease-out",
      }}
      onCanPlay={handleVideoLoad}
      playsInline
      autoPlay
      muted
      loop
    />
  );
};

export default ProgressiveVideo;
