import React from "react";
import ProgressiveImage from "../ProgressiveImage";
import { Link } from "react-router-dom";
import exportIcon from "../../assets/images/icons/Group47.png";
import miningIcon from "../../assets/images/icons/Group53.png";
import investmentsIcon from "../../assets/images/icons/Group49.png";
import "./service-card.css";

const ServiceCards = () => {
  return (
    <>
      <div className="serviceCardsWrapper CardsWrapper">
        <div className="serviceCard">
          <div className="cardIcon">
            <ProgressiveImage
              lowQualitySrc={exportIcon.lowQualityImage}
              highQualitySrc={exportIcon}
              alt={"Exports & Imports"}
              className={"icon"}
            />
          </div>
          <div className="cardTxt">
            <div className="top">
              <h3>Exports & Imports</h3>
            </div>
            <div className="bottom">
              <p className="colorWhite">
                We facilitate seamless cross-border trade, connecting markets
                with a diverse range of high-quality commodities. Our extensive
                network ensures timely and efficient transactions, making us a
                trusted partner in global trade.
              </p>
            </div>
            <div className="moreBtnWrapper">
              <Link to={"/services"} className="moreBtn">
                More
              </Link>
            </div>
          </div>
        </div>

        <div className="serviceCard">
          <div className="cardIcon">
            <ProgressiveImage
              lowQualitySrc={miningIcon.lowQualityImage}
              highQualitySrc={miningIcon}
              alt={"Mining"}
              className={"icon"}
            />
          </div>
          <div className="cardTxt">
            <div className="top">
              <h3>Mining</h3>
            </div>
            <div className="bottom">
              <p className="colorWhite">
                Our mining operations focus on the sustainable extraction of
                precious minerals, including gold, bauxite, and diamonds.
                Committed to responsible mining practices, we ensure minimal
                environmental impact while delivering premium resources.
              </p>
            </div>
            <div className="moreBtnWrapper">
              <Link to={"/services"} className="moreBtn">
                More
              </Link>
            </div>
          </div>
        </div>

        <div className="serviceCard">
          <div className="cardIcon">
            <ProgressiveImage
              lowQualitySrc={investmentsIcon.lowQualityImage}
              highQualitySrc={investmentsIcon}
              alt={"Investments"}
              className={"icon"}
            />
          </div>
          <div className="cardTxt">
            <div className="top">
              <h3>Investments</h3>
            </div>
            <div className="bottom">
              <p className="colorWhite">
                We provide attractive investment opportunities in the mining
                sector, offering sustainable returns through ethical and
                eco-friendly operations. Our investment solutions are designed
                to foster growth and profitability for our stakeholders.
              </p>
            </div>
            <div className="moreBtnWrapper">
              <Link to={"/services"} className="moreBtn">
                More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
