import React from "react";
import "./sustainability.css";
import Banner from "../../components/Banner";
import sustainabilityBannerImg from "../../assets/images/42763.jpg";
import ScrollToTop from "../../components/ScrollToTop";
import ProgressiveImage from "../../components/ProgressiveImage";
import logisticImg from "../../assets/images/logistics-means-transport.jpg";
import co2Img from "../../assets/images/2149675040.jpg";
import wasteImg from "../../assets/images/23440.jpg";
import commImg from "../../assets/images/2148931095.jpg";
import ambulanceImg from "../../assets/images/2149478563.jpg";
import classRoomImg from "../../assets/images/2149080798.jpg";
import eiImage1 from "../../assets/images/2151662956.jpg";
import eiImage2 from "../../assets/images/young-worker-organizing.jpg";
import eiImage3 from "../../assets/images/2150041853.jpg";
import tgImage1 from "../../assets/images/77647.jpg";
import tgImage2 from "../../assets/images/3984.jpg";
import { Link } from "react-router-dom";

const Sustainability = () => {
  return (
    <>
      <Banner
        title={"our commitment to sustainability"}
        bannerImage={sustainabilityBannerImg}
      />
      <div className="bannerDescription">
        <div className="container">
          <article>
            <p>
              We at Drito are committed to encouraging environmentally friendly
              methods in all facets of our mining, importing, and exporting
              business. Our goal is to ensure a better future for future
              generations by striking a balance between social responsibility,
              environmental conservation, and economic prosperity.
            </p>
          </article>
        </div>
      </div>
      <section className="stewardship">
        <div className="container">
          <div className="header susHeader">
            <h2>environmental stewardship</h2>
          </div>
          <div className="elementWrapper">
            <div className="element">
              <div className="left">
                <div className="imgStack">
                  <div className="imgWrapper leftSide">
                    <ProgressiveImage
                      lowQualitySrc={logisticImg.lowQualityImage}
                      highQualitySrc={logisticImg}
                      alt={"cargo containers"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="backLayer backYellow"></div>
                </div>
              </div>
              <div className="right">
                <div className="sticker backYellow negativeLeft">
                  <div className="txtContent">
                    <div className="txtHeader susHeader">
                      <h3>management of sustainable resources</h3>
                    </div>
                    <div className="txt">
                      <p>
                        We place a high priority on the ethical exploitation and
                        use of natural resources, employing cutting-edge methods
                        to reduce our negative environmental effects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="element mobile-column-reverse">
              <div className="left">
                <div className="sticker backBlue positiveRight">
                  <div className="txtContent">
                    <div className="txtHeader susHeader">
                      <h3>Efficiency in Energy Use and Lower Emissions</h3>
                    </div>
                    <div className="txt">
                      <p>
                        To lessen our carbon impact, we have made investments in
                        energy-efficient technologies and renewable energy
                        sources. By 2030, we want to have reduced greenhouse gas
                        emissions by 30%.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="imgStack">
                  <div className="imgWrapper rightSide">
                    <ProgressiveImage
                      lowQualitySrc={co2Img.lowQualityImage}
                      highQualitySrc={co2Img}
                      alt={"cargo containers"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="backLayer backBlue"></div>
                </div>
              </div>
            </div>

            <div className="element">
              <div className="left">
                <div className="imgStack">
                  <div className="imgWrapper leftSide">
                    <ProgressiveImage
                      lowQualitySrc={wasteImg.lowQualityImage}
                      highQualitySrc={wasteImg}
                      alt={"plastic waste"}
                      className={"commodityImage"}
                    />
                  </div>
                  <div className="backLayer backYellow"></div>
                </div>
              </div>
              <div className="right">
                <div className="sticker backYellow negativeLeft">
                  <div className="txtContent">
                    <div className="txtHeader susHeader">
                      <h3>waste management and biodiversity conservation</h3>
                    </div>
                    <div className="txt">
                      <p>
                        Materials reduction, reuse, and recycling are the main
                        goals of our waste management initiatives. Through
                        habitat restoration and replanting initiatives, we also
                        strive to safeguard nearby ecosystems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="socialResponsibility">
        <div className="container">
          <div className="header susHeader">
            <h2>social responsibility</h2>
          </div>
          <div className="socialCards">
            <div className="socialCard">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={commImg.lowQualityImage}
                    highQualitySrc={commImg}
                    alt={"people at woodwork shop"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>Community Engagement</h3>
                  </div>
                  <div className="txt">
                    <p>
                      To promote healthcare efforts, educational activities, and
                      economic development projects, we collaborate with the
                      local community. Encouraging people to live close to our
                      operations is the goal of our community engagement
                      initiatives.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="socialCard">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={ambulanceImg.lowQualityImage}
                    highQualitySrc={ambulanceImg}
                    alt={"man receives first aid from ambulance"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>Human Rights and Health & Safety</h3>
                  </div>
                  <div className="txt">
                    <p>
                      Respecting the best human rights and labor practices is
                      something we're devoted to doing. We guarantee a secure
                      workplace for every employee through our extensive health
                      and safety initiatives.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="socialCard">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={classRoomImg.lowQualityImage}
                    highQualitySrc={classRoomImg}
                    alt={"people in a classroom"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>Education and Training</h3>
                  </div>
                  <div className="txt">
                    <p>
                      We encourage skill development and career advancement
                      among our staff members by offering continuous training
                      and development opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="economicImpact">
        <div className="container">
          <div className="header susHeader">
            <h2>economic impact</h2>
          </div>
          <div className="e-i-elementWrapper">
            <div className="e-i-element">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={eiImage1.lowQualityImage}
                    highQualitySrc={eiImage1}
                    alt={"container area"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>local economic development</h3>
                  </div>
                  <div className="txt">
                    <p>
                      We support the regional economies in which we operate by
                      investing in infrastructure, creating jobs, and purchasing
                      locally.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="e-i-element">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={eiImage2.lowQualityImage}
                    highQualitySrc={eiImage2}
                    alt={"young man at warehouse"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>local economic development</h3>
                  </div>
                  <div className="txt">
                    <p>
                      We support the regional economies in which we operate by
                      investing in infrastructure, creating jobs, and purchasing
                      locally.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="e-i-element">
              <div className="top">
                <div className="imgWrapper">
                  <ProgressiveImage
                    lowQualitySrc={eiImage3.lowQualityImage}
                    highQualitySrc={eiImage3}
                    alt={"woman on phone"}
                    className={"commodityImage"}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="txtContent">
                  <div className="txtHeader susHeader">
                    <h3>local economic development</h3>
                  </div>
                  <div className="txt">
                    <p>
                      We support the regional economies in which we operate by
                      investing in infrastructure, creating jobs, and purchasing
                      locally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Transparency">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imgContent">
                <div className="left">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={tgImage1.lowQualityImage}
                      highQualitySrc={tgImage1}
                      alt={"men at workshop"}
                      className={"commodityImage"}
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="imgWrapper">
                    <ProgressiveImage
                      lowQualitySrc={tgImage2.lowQualityImage}
                      highQualitySrc={tgImage2}
                      alt={"women at workshop"}
                      className={"commodityImage"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="contentWrapper">
                <div className="content">
                  <div className="top">
                    <div className="txtContent">
                      <div className="txtHeader susHeader">
                        <h3>Ethical Practices and Compliance</h3>
                      </div>
                      <div className="txt">
                        <p>
                          We follow stringent anti-corruption protocols and
                          ethical standards. We ensure ethical business
                          practices by operating in accordance with local and
                          international regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="txtContent">
                      <div className="txtHeader susHeader">
                        <h3>Reporting and Accountability</h3>
                      </div>
                      <div className="txt">
                        <p>
                          When it comes to our environmental initiatives, we are
                          dedicated to openness. We make ourselves accountable
                          to our stakeholders by providing an annual
                          sustainability report that outlines our
                          accomplishments and progress.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="successStories">
        <div className="container">
          <div className="storyWrapper">
            <div className="storyCard">
              <div className="txtContent">
                <div className="txtHeader susHeader">
                  <h3>positive impact</h3>
                </div>
                <div className="txt">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Excepturi minima nemo temporibus nostrum qui rerum
                    consequatur quaerat veniam consequuntur odit.
                  </p>
                </div>
                <div className="btnWrapper">
                  <Link to={"/sustainability"} className="readMoreBtn">
                    read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Sustainability;
