import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Notification from "../notification";
import "./forms.css";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("info");

  const handleNotification = (message, type) => {
    setShowNotification(true);
    setNotificationMessage(message);
    setNotificationType(type);

    // hide the notification after 5 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <Formik
        initialValues={{
          fullName: "",
          emailAddress: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/contact`, values)
            .then((response) => {
              console.log("Form data sent successfully:", response.data);
              resetForm();
              setSubmitting(false);
              handleNotification("Sent successfully!", "success");
            })
            .catch((error) => {
              console.error("There was an error sending the form data:", error);
              setSubmitting(false);
              handleNotification(
                "Failed to send. Please try again later.",
                "error"
              );
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-field">
              <label>Full Name</label>
              <Field type="text" name="fullName" />
              <ErrorMessage
                name="fullName"
                component="div"
                className="error-message"
              />
            </div>

            <div className="form-field">
              <label>Email Address</label>
              <Field type="email" name="emailAddress" />
              <ErrorMessage
                name="emailAddress"
                component="div"
                className="error-message"
              />
            </div>

            <div className="form-field">
              <label>Message</label>
              <Field
                as="textarea"
                name="message"
                rows="5"
                style={{
                  width: "100%",
                  minHeight: "100px",
                  resize: "vertical",
                }}
              />
              <ErrorMessage
                name="message"
                component="div"
                className="error-message"
              />
            </div>

            <button
              className="btnPrimary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default ContactForm;
